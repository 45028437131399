import React from 'react';
import menuImg from '../images/sidebar-menu.png';
import findUsImg from '../images/sidebar-find-us.png';
import { Link } from 'gatsby';

export default function Sidebar() {
  return (
    <section className="hidden md:block w-4/12 lg:w-3/12">
      <Link to="/our-menu" className="block mb-4 bg-light-blue text-dark-blue text-xl hover:bg-dark-blue hover:text-light-blue">
        <img src={menuImg} alt="Food" />
        <p className="px-3 py-2">Our Menu</p>
      </Link>
      <Link to="/find-us" className="block bg-light-blue text-dark-blue text-xl hover:bg-dark-blue hover:text-light-blue">
        <img src={findUsImg} alt="Map" />
        <p className="px-3 py-2">Find Us</p>
      </Link>
    </section>
  );
}
