import { graphql } from 'gatsby';
import React from 'react';
import Sidebar from '../components/Sidebar';
import Layout from '../layouts/Layout';

export default function IndexPage({ data }: { data: any }) {
  return (
    <Layout>
      <title>Vicarage Road Chippy | Morriston, Swansea</title>
      <div className="flex">
        <section className="markdown md:mr-4 md:w-8/12 lg:w-9/12" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></section>
        <Sidebar />
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: { regex: "/home.md/" }) {
      html
    }
  }
`;
